<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="小区" prop="villageId">
                <a-input v-model="queryParam.title" placeholder="请输入小区" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="用户" prop="userId">
                <a-input v-model="queryParam.nickName" placeholder="请输入用户" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="审核状态" prop="isOpen">
                <a-select v-model="queryParam.approveStatus" placeholder="请选择 " style="width: 100%" allow-clear>
                  <a-select-option :value="0">待审核</a-select-option>
                  <a-select-option :value="1">审核通过</a-select-option>
                  <a-select-option :value="2">审核驳回</a-select-option>
                </a-select>

              </a-form-item>
            </a-col>
            <template v-if="advanced">

              <a-col :md="6" :sm="24">
                <a-form-item label="订单号" prop="orderNo">
                  <a-input v-model="queryParam.orderNo" placeholder="请输入订单号" allow-clear/>
                </a-form-item>
              </a-col>

              <a-col :md="8" :sm="24">
                <a-form-item label="支付时间" prop="payTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.payTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
                </a-form-item>
              </a-col>

            </template>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
<!--                <a @click="toggleAdvanced" style="margin-left: 8px">-->
<!--                  {{ advanced ? '收起' : '展开' }}-->
<!--                  <a-icon :type="advanced ? 'up' : 'down'"/>-->
<!--                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">

        <a-button type="danger" :disabled="multiple" @click="handleDelete"  >
          <a-icon type="delete" />删除
        </a-button>
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['user:order:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
        <a-button type="primary" :disabled="multiple" @click="handleApprove"  >
          <a-icon type="edit" />批量审核通过
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <approve-modal ref='approveModal' @ok="getList"></approve-modal>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="payTime" slot-scope="text, record">
          {{ parseTime(record.payTime) }}
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
<!--          <a-divider type="vertical" v-hasPermi="['user:order:edit']" />-->
<!--          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['user:order:edit']">-->
<!--            <a-icon type="edit" />修改-->
<!--          </a>-->
<!--           <a v-if='record.approveStatus == 0' @click="$refs.approveModal.audit(record)"  >-->
<!--            <a-icon type="edit" />审核-->
<!--          </a>-->
          <a-divider type="vertical"   />
          <a @click="handleDelete(record)"  >
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageOrder,listOrder,batchApprove, delOrder } from '@/api/user/order'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import ApproveModal from './modules/ApproveModal.vue'

export default {
  name: 'Order',
  components: {
    ApproveModal,
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        title: null,
        nickName: null,
        orderNo: null,
        payNo: null,
        paymentType: null,
        outTrandNo: null,
        topTime: null,
        topType: null,
        payMoney: null,
        topId: null,
        payTime: null,
        status: null,
        isDistributor: null,
        distributorUserId: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '序号',
          ellipsis: true,
          customRender: (text, record, index) => index + 1,
          align: 'center',
          width: 80
        },
        {
          title: '小区',
          dataIndex: 'title',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用户昵称',
          dataIndex: 'nickName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '订单号',
          dataIndex: 'orderNo',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '支付单号',
        //   dataIndex: 'payNo',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '支付类型',
          dataIndex: 'paymentType',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '三方订单号',
          dataIndex: 'outTrandNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '置顶时长',
          dataIndex: 'topTime',
          ellipsis: true,
          align: 'center',
          customRender: function (t, r, index) {

            return t + (r.topType == 0 ? "（小时）" : "（天）")

          }
        },

        {
          title: '支付金额',
          dataIndex: 'payMoney',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '置顶价格菜单id',
        //   dataIndex: 'topId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '支付时间',
          dataIndex: 'payTime',
          scopedSlots: { customRender: 'payTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '支付状态',
          dataIndex: 'status',
          ellipsis: true,
          customRender: function (t) {
            if (t == 0) {
              return "待支付";
            } else if (t == 1) {
              return "支付成功";
            } else if (t == 2) {
              return "支付失败";
            }
          },
          align: 'center'
        },
        {
          title: '审核状态',
          dataIndex: 'approveStatus',
          ellipsis: true,
          customRender: function (t) {
            if (t == 0) {
              return "待审核";
            } else if (t == 1) {
              return "审核成功";
            } else if (t == 2) {
              return "审核失败";
            }
          },
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },

        {
          title: '操作',
          dataIndex: 'operation',
          width: '8%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询置顶订单列表 */
    getList () {
      this.loading = true
     pageOrder(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        title: null,
        nickName: null,
        orderNo: undefined,
        payNo: undefined,
        paymentType: undefined,
        outTrandNo: undefined,
        topTime: undefined,
        topType: undefined,
        payMoney: undefined,
        topId: undefined,
        payTime: undefined,
        status: undefined,
        isDistributor: undefined,
        distributorUserId: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delOrder(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },

        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    handleApprove (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认审核通过所选中数据?',
        content: '（默认审核支付成功的）当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return batchApprove(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '成功',
                3
              )
            })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('user/top-order/export', {
            ...that.queryParam
          }, `置顶订单_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
