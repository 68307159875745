import request from '@/utils/request'


// 查询用户入驻申请列表
export function listJoinApply(query) {
  return request({
    url: '/user/user-join-applys/list',
    method: 'get',
    params: query
  })
}
export function userJoinList(query) {
  return request({
    url: '/user/user-join-applys/userJoinList',
    method: 'get',
    params: query
  })
}

// 查询用户入驻申请分页
export function pageJoinApply(query) {
  return request({
    url: '/user/user-join-applys/page',
    method: 'get',
    params: query
  })
}

// 查询用户入驻申请详细
export function getJoinApply(data) {
  return request({
    url: '/user/user-join-applys/detail',
    method: 'get',
    params: data
  })
}

// 新增用户入驻申请
export function addJoinApply(data) {
  return request({
    url: '/user/user-join-applys/add',
    method: 'post',
    data: data
  })
}

// 修改用户入驻申请
export function updateJoinApply(data) {
  return request({
    url: '/user/user-join-applys/edit',
    method: 'post',
    data: data
  })
}

// 删除用户入驻申请
export function delJoinApply(data) {
  return request({
    url: '/user/user-join-applys/delete',
    method: 'post',
    data: data
  })
}
export function batchApprove(data) {
  return request({
    url: '/user/user-join-applys/batchApprove',
    method: 'post',
    data: data
  })
}
export function approve(data) {
  return request({
    url: '/user/user-join-applys/approve',
    method: 'post',
    data: data
  })
}
